import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { evaluateTraning } from 'services/Api/Training';
import { loading } from 'store/ducks/general';
import { selectTags, setSteps, selectMachineGroup } from 'store/ducks/training';

import CustomItemButtom, { iconType } from 'components/CustomItemButtom';
import { toast } from 'react-toastify';
import { t, strings } from 'language';
import StepSlider from './stepSlider';
import {
  PlaceholderTextContainer,
  LinkSelectFolders,
  GrayText,
  ContainerFoldersRow
} from './styles';

export default function NewTraining() {
  const dispatch = useDispatch();
  const training = useSelector(state => state.training);
  const [tags, setTags] = useState([]);
  // const [machineGroupSelected, setMachineGroupSelected] = useState('gpu_t3');

  const getData = useCallback(async () => {
    dispatch(loading(true));
    const trainingIds = training.selectedTraningDatasets.map(i => i.id);
    const evalIds = training.selectedEvalDatasets.map(i => i.id);
    dispatch(loading(true));
    const result = await evaluateTraning(training.model.id, trainingIds, evalIds);
    dispatch(loading(false));
    if (result.warning) toast.warn(result.message);
    setTags(result.tags);
    dispatch(loading(false));
  }, [
    training.selectedTraningDatasets,
    training.selectedEvalDatasets,
    training.model.id,
    dispatch
  ]);

  useEffect(() => {
    getData();
    return () => {};
  }, [getData]);

  const isbuttonSelected = useCallback(
    item => {
      return training.selectedTags.find(i => i.tag === item.tag) ? true : false;
    },
    [training.selectedTags]
  );

  const handleClickTag = useCallback(
    tagItem => {
      if (isbuttonSelected(tagItem)) {
        dispatch(selectTags(training.selectedTags.filter(i => i.tag !== tagItem.tag)));
      } else dispatch(selectTags([...training.selectedTags, tagItem]));
    },
    [dispatch, isbuttonSelected, training.selectedTags]
  );

  const selectAll = useCallback(() => {
    dispatch(selectTags(tags.filter(i => i.error === false)));
  }, [dispatch, tags]);

  const deselectAll = useCallback(() => {
    dispatch(selectTags([]));
  }, [dispatch]);

  // const handleMachineGroupSelect = type => {
  //   dispatch(selectMachineGroup(type));
  //   setMachineGroupSelected(type);
  // };
  return (
    <div className="row">
      <div className="col-12 col-lg-8 col-xl-8">
        <GrayText>{t(strings.steps_number)}</GrayText>
        <StepSlider onChange={value => dispatch(setSteps(value))} value={training.steps} />
      </div>
      {/* <div className="col-12">
        <GrayText>{t(strings.machine_type)}</GrayText>
        <CustomItemButtom
          width={261}
          onClick={() => handleMachineGroupSelect('gpu_t3')}
          selected={machineGroupSelected === 'gpu_t3' ? true : false}
        >
          {t(strings.machine_type_eco)}
        </CustomItemButtom>
        <CustomItemButtom
          width={261}
          onClick={() => handleMachineGroupSelect('gpu_t2')}
          selected={machineGroupSelected === 'gpu_t2' ? true : false}
        >
          {t(strings.machine_type_bal)}
        </CustomItemButtom>
        <CustomItemButtom
          width={261}
          onClick={() => handleMachineGroupSelect('gpu_t1')}
          selected={machineGroupSelected === 'gpu_t1' ? true : false}
        >
          {t(strings.machine_type_fast)}
        </CustomItemButtom>
      </div> */}
      <div className="col-12">
        <GrayText>{t(strings.train_choose_tag)}</GrayText>
        <LinkSelectFolders onClick={selectAll}>{t(strings.select_all)}</LinkSelectFolders>
        <LinkSelectFolders onClick={deselectAll}>{t(strings.uncheck_all)}</LinkSelectFolders>
        <ContainerFoldersRow height="210px">
          {tags.length > 0 ? (
            <>
              <div>
                <p>{t(strings.train_already_trained)}:</p>
                {tags
                  .filter(i => i.trained)
                  .map((item, index) => {
                    return (
                      <CustomItemButtom
                        key={index}
                        error={item.error}
                        warning={item.warning}
                        message={item.message}
                        selected={isbuttonSelected(item)}
                        onClick={() => handleClickTag(item)}
                        className="float-left"
                        width={261}
                        icon={iconType.tag}
                      >
                        {item.tag}
                      </CustomItemButtom>
                    );
                  })}
              </div>
              <div>
                <p>{t(strings.train_not_trained)}:</p>
                {tags
                  .filter(i => !i.trained)
                  .map((item, index) => {
                    return (
                      <CustomItemButtom
                        key={index}
                        error={item.error}
                        warning={item.warning}
                        message={item.message}
                        selected={isbuttonSelected(item)}
                        onClick={() => handleClickTag(item)}
                        className="float-left"
                        width={261}
                        icon={iconType.tag}
                      >
                        {item.tag}
                      </CustomItemButtom>
                    );
                  })}
              </div>
            </>
          ) : (
            <PlaceholderTextContainer>{t(strings.no_folders_selected)}</PlaceholderTextContainer>
          )}
        </ContainerFoldersRow>
      </div>
    </div>
  );
}
